import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { AuthProvider } from "./AuthProvider";

import AuthPage from './pages/authPage'
import GamesListPage from './pages/gamesList';
import GameplayPage from './pages/gamePlay';
import GameSummaryPage from './pages/gameSummary';

function App() {
  return (
    <div className="App">
      <Router>
      <AuthProvider>
          <div>
            <Routes>
            <Route exact path='/' element={<AuthPage/>} />
              <Route exact path='/games' element={<GamesListPage/>} />
              <Route path='/play/:playId' element={<GameplayPage/>} />
              <Route path='/play/:playId/summary' element={<GameSummaryPage/>} />
            </Routes>
          </div>
        </AuthProvider>
        </Router>      
    </div>
  );
}

export default App;
