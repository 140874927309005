import './audioController.css';

import { useState } from 'react';

export default function AudioController(props) {
    const [currentTime, setCurrentTime] = useState(0);

    function moveSlider(val) {
        setCurrentTime(val.target.value);
        var audio = document.getElementById(props.audioId);
        if (audio != null) {
            var duration = audio.duration
            audio.currentTime = duration * val.target.value;
        }
    };

    function onTimeUpdate(e) {
        var percentPlayed = e.target.currentTime / e.target.duration
        setCurrentTime(percentPlayed);
        props.onAudioUpdate();
    };

    function onAudioLoad() {
        console.log("okay getting audio");
        var audio = document.getElementById(props.audioId);
        if (audio != null) {
            console.log("sending duration");
            console.log(audio.duration);
            props.onAudioLoad(audio.duration);
        }
    };

    return (
        <div>
            <audio id={props.audioId} preload="auto" src={props.src} onTimeUpdate={onTimeUpdate} onLoad={onAudioLoad()}></audio>
            <div className="slidecontainer">
                <input type="range" min="0" max="1" step="0.001" value={currentTime} className="slider" onInput={moveSlider} onChange={moveSlider} />
            </div>
        </div>
    );
}