import {ReactComponent as WaveIcon} from '../assets/wave.svg';

export default function RoundSidebar(props) {
    return(
        <div className="dashboard-sidebar">
            <div className="sidebar-inner">
                <h2 className="text-title text-center">{props.gameName}</h2>
                <div className="wave-container">
                    <WaveIcon id="wave"/>
                </div>
                <div className="main-content text-center">
                    {props.rounds.map((round, idx) => {
                        const itemClass = round.roundNum === props.activeRound ? 'text-large' : 'test-large text-white-50';
                        if (round.roundNum === props.rounds.length){
                            return(
                                <div className='button-text' key={idx} onClick={() => props.onRoundClick(round.roundNum)}> 
                                    <p className={itemClass}>{round.roundName}</p>
                                </div>
                            ); 
                        }else{
                            return(
                                <div className='button-text' key={idx} onClick={() => props.onRoundClick(round.roundNum)}> 
                                    <p className={itemClass}>{round.roundName}</p>
                                    <div className='divider-line'></div>
                                </div>
                            );
                        }
                    }, this)}
                    <div className={props.activeRound === props.rounds.length ? 'cta-disabled' : 'cta-container'} onClick={() => props.onNextClick()}>
                        <p>Next round</p>
                    </div>
                    <div className='cta-link button' onClick={() => props.onExitClick()}>
                        <p>Exit quiz</p>
                    </div>
                    <div className='cta-link button critical' onClick={() => props.onGiveUpClick()}>
                        <p>Give up</p>
                    </div>
                </div>
            </div>
        </div>
    );
}