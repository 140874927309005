import React from "react";
import { useEffect, useState, useContext } from 'react';
import Round from "../components/round";
import AuthContext from "../AuthContext";
import Navbar from "../components/navbar";
import RoundSidebar from "../components/roundSidebar";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { ApiService } from "../services/apiService"

export default function GameplayPage() {
    const [round, setRound] = useState({ roundNum: 0, roundName: '', questions: [] })
    const [play, setPlay] = useState({
        playId: 0, gameId: 0, gameName: '',
        displayedAnswers: [], rounds: [], userPlaytimeMins: 0, userStartTime: 0
    })
    const [displayedAnswers, setDisplayedAnswers] = useState([]);
    const [playNeedsUpdate, setPlayNeedsUpdate] = useState(false);
    const [inputError, setInputError] = useState(false);

    const [searchParams] = useSearchParams();
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();

    const { groups: { playId } } = /\/play\/(?<playId>[a-zA-Z0-9]+)/.exec(location.pathname);

    useEffect(() => {
        async function getPlay() {
            if (!user) {
                return;
            }
            ApiService.getPlay(user.uid, playId).then(result => {
                setPlay(result);
                setDisplayedAnswers(result.displayedAnswers);
            });
        };

        getPlay();
    }, [playId, user]);

    useEffect(() => {
        async function getRound() {
            if (play.gameId === 0) {
                // TODO: clean this up to have an un-initialized starter data
                return;
            }
            if (!user) {
                return;
            }
            const roundNum = parseInt(searchParams.get('round'));
            // TODO: make this match the "index from 1" approach in storage
            ApiService.getRound(user.uid, play.gameId, roundNum).then(result => {
                setRound(result);
            });
        };

        getRound();
    }, [play.gameId, searchParams, user]);

    useEffect(() => {
        async function updatePlay() {
            if (!user || !play || play.gameId === 0 || playId === 0 || play.playId === 0 || !playNeedsUpdate) {
                return;
            }
            ApiService.updatePlay(user.uid, play).then(result => {
                setPlay(result);
                setDisplayedAnswers(result.displayedAnswers);
                setPlayNeedsUpdate(false);
            });
        };

        updatePlay();
    }, [play, play.displayedAnswers, playId, playNeedsUpdate, user]);

    function compareAnswer(attempt, answer) {
        // For multi-answer, we use ; to separate
        const answers = answer.split(';');
        return answers.map((candidate) => {
            // Clean the input text
            const cleanText = attempt.trim().toLowerCase().replace('\'', '').replace(',', '');
            const cleanAnswer = candidate.trim().toLowerCase().replace('\'', '').replace(',', '');
            return {
                isCorrect: cleanText === cleanAnswer,
                answer: cleanAnswer,
            };
        });
    };

    function checkAnswer(answerType, attempt, questionIdx, shakeOnWrong) {
        setInputError(false);
        if (answerType !== "ARTIST" && answerType !== "TITLE") {
            return;
        }
        if (attempt === "") {
            return;
        }
        if (questionIdx < 0 || questionIdx > round.questions.length) {
            console.log("Warning, attempting to check answer on invalid question index " + questionIdx);
            return;
        }

        // TODO: use answerHash instead of data
        const answerComparisons = round.questions[questionIdx].answers.filter(
            (answer) => answer.answerType === answerType).map(
                (answer) => answer.answerText).flatMap(
                    (data) => compareAnswer(attempt, data));
        const correct = answerComparisons.filter((ans) => ans.isCorrect).length > 0;
        if (correct) {
            // Display the first in the list of answerType, since the answers are ordered
            // by dsiplay precedents
            const displayedAnswer = round.questions[questionIdx].answers.filter(
                (answer) => answer.answerType === answerType)[0].answerText;
            if (play.displayedAnswers === null) {
                play.displayedAnswers = [];
            }
            play.displayedAnswers.push(
                {
                    roundNum: round.roundNum,
                    questionId: questionIdx,
                    answerType: answerType,
                    data: displayedAnswer,
                }
            );
            setDisplayedAnswers(play.displayedAnswers);
            setPlayNeedsUpdate(true);
        }
        if (!correct && shakeOnWrong) {
            setInputError(true);
        }
    };

    function nextRound() {
        // TODO: UpdatePlay with stats
        if (round.roundNum >= play.rounds.length) {
            // Already at last round
            return;
        }
        navigate(`/play/${playId}?round=${++round.roundNum}`);
    };

    function navToRound(round) {
        navigate(`/play/${playId}?round=${round}`);
    };

    function exitQuiz() {
        // TODO: UpdatePlay with pause time, stats
        navigate(`/games`);
    };

    function giveUp() {
        // TODO: UpdatePlay with give-up notice
        navigate(`/play/${playId}/summary`);
    };

    if (!user && false) {
        return <div /> //<Navigate to="/" replace />
    } else {
        return (
            <section className="dashboard-container">
                <div className="dashboard-main-container">
                    <Navbar />
                    <Round
                        roundNum={round.roundNum}
                        name={round.name}
                        questions={round.questions}
                        displayedAnswers={displayedAnswers.filter((ans) => ans.roundNum === round.roundNum)}
                        startTime={play.lastResumedTime}
                        addedTimeMins={play.userPlaytimeMins}
                        checkAnswer={checkAnswer}
                        errorClass={inputError ? 'error' : ''}
                    />
                </div>
                <div className="dashboard-sidebar-container">
                    <RoundSidebar
                        gameName={play.gameName}
                        activeRound={round.roundNum}
                        rounds={play.rounds}
                        onExitClick={exitQuiz}
                        onGiveUpClick={giveUp}
                        onNextClick={nextRound}
                        onRoundClick={navToRound}
                    />
                </div>
            </section>
        );
    }
}