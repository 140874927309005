import Question from "../components/question";
import Clock from "../components/clock";
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Round(props) {
    const [activeQuestion, setActiveQuestion] = useState(0)

    function getQuestionState(idx){
        if (!props.displayedAnswers || props.displayedAnswers.length === 0){
            return 'NONE';
        }

        const answers = props.displayedAnswers.filter((answer) => answer.questionId === idx);
        const artistAnswers = answers.filter((answer) => answer.answerType === 'ARTIST');
        const titleAnswers = answers.filter((answer) => answer.answerType === 'TITLE');

        let total = 0;
        if(titleAnswers.length > 0){
            total += 1;
        }
        if(artistAnswers.length > 0 ){
            total += 1;
        }
        
        switch(total){
            case 1:
                return 'HALF';
            case 2: 
            return 'COMPLETE';
            case 0:
            default:
                return 'NONE';
        }
    };

    function getAnswerDisplay(idx, displayedAnswers){
        if (!displayedAnswers || displayedAnswers.length === 0){
            return;
        }
        const answers = displayedAnswers.filter((answer) => answer.questionId === idx);
        const artistAnswers = answers.filter((answer) => answer.answerType === 'ARTIST');
        const titleAnswers = answers.filter((answer) => answer.answerType === 'TITLE');

        return {
            title: titleAnswers.length > 0 ? titleAnswers[0].data : '',
            artist: artistAnswers.length > 0 ? artistAnswers[0].data : '',
        }
    };

    return (
        <div className='dashboard-main'>
            <div className='main-inner'></div>
                <div className='meta-container'>
                    <h2 className='text-title'>{props.name}</h2>
                    {props.startTime && props.startTime > 0 ?
                        <Clock startTime={props.startTime} addedTimeMins={props.addedTimeMins}/>  : 
                        <div/>
                    }                    
                </div>
                <div className='quiz-container'>
                {props.questions && props.questions.length > 0 ? props.questions.map((q, idx) => (
                        <Question 
                            key={idx}
                            questionIdx={idx}
                            name={q.name}
                            src={q.url}
                            onClick={() => setActiveQuestion(idx)}
                            checkAnswer={props.checkAnswer}
                            errorClass={props.errorClass}
                            questionState={getQuestionState(idx)}
                            answerDisplay={getAnswerDisplay(idx, props.displayedAnswers)}
                            active={idx === activeQuestion ? "active" : ""}/>
                    ), this) : <CircularProgress />} 
                </div>
        </div>
    );
}