import {ReactComponent as WaveIcon} from '../assets/wave.svg';

export default function GameListSidebar(props) {
    return(
        <div className="dashboard-sidebar">
            <div className="sidebar-inner">
                <h2 className="text-title text-center">{props.activeGame ? 'Now Playing' : 'Up Next'}</h2>
                <div className="wave-container">
                    <WaveIcon id="wave"/>
                </div>
                <div className="main-content text-center">
                    <p className='text-large'>{props.name}</p>
                    <p className='text-white-50'>Playtime {props.playMins} mins</p>
                    <div className='divider-line'></div>
                    <p className='text-white-50'>
                        {props.numArtists} artists and {props.numSongs} songs
                        <br/>
                        {props.activeGame && 'still'} to guess
                    </p>
                    <div className='cta-container' onClick={() => props.onPlayClick()}>
                        <p>{props.activeGame ? 'Resume' : 'Play'}</p>
                    </div>
                    {props.activeGame &&
                        <div className='cta-link' onClick={() => props.onRestartClick()}>
                            <p>Restart</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}