import { ReactComponent as PlayIcon } from '../assets/play.svg';
import { ReactComponent as PauseIcon } from '../assets/pause.svg';
import { ReactComponent as MiniWaveIcon } from '../assets/mini-wave.svg';
import { useRef, useState, useEffect } from 'react';
import AudioController from './audioController';
import { ReactComponent as CheckIcon } from '../assets/check.svg';
import { ReactComponent as CircleIcon } from '../assets/circle.svg';
import { ReactComponent as HalfCircleIcon } from '../assets/half-circle.svg';

export default function Question(props) {

    const titleInputRef = useRef(null);
    const artistInputRef = useRef(null);

    const [audioPlaying, setAudioPlaying] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);

    // const [answerDisplayStyle, setAnswerDisplayStyle] = useState("");

    // useEffect(() => {
    //     async function updateAnswerDisplay() {
    //         if (!props.answerDisplay) {
    //             return;
    //         }

    //         if (props.answerDisplay.title !== '' || props.answerDisplay.artist !== '') {
    //             setAnswerDisplayStyle("correct-answer");
    //             if (props.answerDisplay.gaveUp) {
    //                 setAnswerDisplayStyle("wrong-answer");
    //             }
    //         }
    //     };
    //     updateAnswerDisplay();
    // }, [props.answerDisplay]);

    useEffect(() => {
        async function updateArtistInput() {
            if (props.answerDisplay?.artist) {
                artistInputRef.current.value = props.answerDisplay.artist;
            } else {
                artistInputRef.current.value = '';
            }
        };
        async function updateTitleInput() {
            if (props.answerDisplay?.title) {
                titleInputRef.current.value = props.answerDisplay.title;
            } else {
                titleInputRef.current.value = '';
            }
        };

        updateArtistInput();
        updateTitleInput();
    }, [props.answerDisplay]);

    function onAudioLoad(duration) {
        console.log("audio load");
        console.log(duration);
        setAudioDuration(toMMSS(duration));
    };

    function renderIcon(questionState) {
        switch (questionState) {
            case 'NONE':
                return <CircleIcon id='circle' />
            case 'HALF':
                return <HalfCircleIcon id='half-circle' />
            case 'COMPLETE':
            default:
                return <CheckIcon id='check' />
        };
    };

    function play() {
        const currentAudioId = "audio" + props.questionIdx;
        var audio = document.getElementById(currentAudioId);

        if (!audio.paused) {
            audio.pause();
            return;
        }

        var allAudios = document.getElementsByTagName("audio");
        // Pause all other audios when playing a new one.
        for (const audioElement of allAudios) {
            if (audioElement.id !== currentAudioId) {
                audioElement.pause();
            }
        }

        audio.play();
    };

    function updateAudioDisplay() {
        const currentAudioId = "audio" + props.questionIdx;
        var audio = document.getElementById(currentAudioId);
        if (audio) {
            setAudioPlaying(!audio.paused);
        }
    };

    function toMMSS(str) {
        // TODO: fix parsing and combine this logic with the one in clock.js
        var sec_num = parseInt(str, 10);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return minutes + ':' + seconds;
    };

    function onKeyUpTitle(event) {
        var shakeOnWrong = event.keyCode === 13;
        props.checkAnswer("TITLE", titleInputRef.current.value, props.questionIdx, shakeOnWrong);
    };

    function onKeyUpArtist(event) {
        var shakeOnWrong = event.keyCode === 13;
        props.checkAnswer("ARTIST", artistInputRef.current.value, props.questionIdx, shakeOnWrong);
    }

    return (
        <div className={`game-list-entry ${props.active}`} onClick={props.onClick}>
            <div className='question-container' id={"question" + props.questionIdx}>
                <div className='play-container' onClick={() => play()}>
                    {audioPlaying ? <PauseIcon id="pause" /> : <PlayIcon id="play" />}
                    <p className="text-large text-white">{props.name}</p>
                </div>
                <div className='audio-container'>
                    <AudioController
                        src={props.src}
                        audioId={"audio" + props.questionIdx}
                        onAudioUpdate={updateAudioDisplay}
                        onAudioLoad={onAudioLoad}
                    />
                </div>
                <div className='meta-container'>
                    <div className='mini-wave-container'>
                        <MiniWaveIcon id="mini-wave" />
                    </div>
                    <p className="text-large text-white-50">{audioDuration}</p>
                    <div className='icon-container'>
                        {renderIcon(props.questionState)}
                    </div>
                </div>
            </div>

            <div className='submit-container'>
                <form>
                    <input
                        id="title-input"
                        type="text"
                        ref={titleInputRef}
                        placeholder={props.answerDisplay?.title ? props.answerDisplay.title : 'Song'}
                        className={'text-center ' + props.errorClass + ' '}
                        disabled={props.answerDisplay && props.answerDisplay.title !== ''}
                        onKeyUp={onKeyUpTitle}
                    />
                    <input
                        id="artist-input"
                        type="text"
                        ref={artistInputRef}
                        placeholder={props.answerDisplay?.artist ? props.answerDisplay.artist : 'Artist'}
                        className={'text-center ' + props.errorClass + ' '}
                        disabled={props.answerDisplay && props.answerDisplay.artist !== ''}
                        onKeyUp={onKeyUpArtist}
                    />
                </form>
            </div>
        </div>
    );
}