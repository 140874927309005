import {ReactComponent as PlayIcon} from '../assets/play.svg';
import {ReactComponent as CheckIcon} from '../assets/check.svg';
import {ReactComponent as CircleIcon} from '../assets/circle.svg';
import {ReactComponent as HalfCircleIcon} from '../assets/half-circle.svg';

export default function GameListEntry(props) {

    function renderIcon(playState){
        switch(playState) { 
            case 'NEVER':
                return <CircleIcon id='circle'/>
            case 'PAUSED':
                return <HalfCircleIcon id='half-circle'/>
            case 'COMPLETE':
            default:
                return <CheckIcon id='check'/>
          };
    }

    return (
        <div className='game-list-entry' onClick={() => props.onClick()}>
            <div className='play-container'>
                <PlayIcon id="play"/>
                <p className="text-large text-white">{props.name}</p>
            </div>
            <div className='meta-container'>
                <p className="text-large text-white-50">Playtime {props.playMins} mins</p>
                <div className='icon-container'>
                    {renderIcon(props.playState)}
                </div>
            </div>
        </div>
    );
}