import { ReactComponent as Logo } from '../assets/logo.svg';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useState, useEffect } from 'react';
import { useContext } from "react";
import AuthContext from "../AuthContext";

export default function Navbar() {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    const [username, setUsername] = useState("Friend");

    useEffect(() => {
        async function updateName() {
            if (user && user.email) {
                setUsername(user.email);
            }
        };
        updateName();
    }, [user]);

    function handleLogout() {
        signOut(auth);
        navigate('/');
    };

    return (
        <div className="nav">
            <div className="nav-inner">
                <div className="dashboard-logo" onClick={() => navigate('/games')}>
                    <Logo id="logo" />
                    <h1 className="text-logo">Squawk appella</h1>
                </div>
                <div className="welcome-message">
                    <p className="cta-link text-accent" onClick={handleLogout}>Hi {username} (logout)</p>
                </div>
            </div>
        </div>
    );
}