import React from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../AuthContext";

export default function GameSummaryPage() {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const [play, setPlay] = useState({ id: 0});
    const location = useLocation();

    useEffect(() => {
        async function getPlay() {
            const { groups: { playId } } = /\/play\/(?<playId>[a-z|A-Z|0-9]+)\/summary/.exec(location.pathname)
            
            // TODO: Call to get the play summary
            setPlay({
                id: playId,
                questionsCorrect: 68,
                questionsTotal: 75,
                playtimeMins: 28,
            });
        };
        getPlay();
    }, [location]);

    if(!user && false){
        return <Navigate to="/" replace />
    }else{
        return (
            <div>
            <section id="login-container" className="hero-card-container">
                <div className="login-card-container">
                    <div className="answers-container">
                        <p className="text-large text-center">Your Score</p>
                        <p className="text-center">{play.questionsCorrect} out of {play.questionsTotal} questions in {play.playtimeMins} minutes</p>
                    </div>
                    <div className="cta-container text-center button" onClick={() => navigate(`/games`)}>
                        Back to Home
                    </div>
                    <div className="cta-container text-center button" onClick={() => navigate(`/play/${play.id}?round=1`)}>
                        Answers
                    </div>
                </div>
            </section>
        </div>
        );
    }
}