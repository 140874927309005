import {ReactComponent as ClockIcon} from '../assets/clock.svg';
import { useEffect, useState } from 'react';

export default function Clock(props) {
    const [playtime, setPlaytime] = useState(new Date());
  
   useEffect(() => {
        if (props.startTime){
            var timerID = setInterval( () => setPlaytime(new Date().getTime() - props.startTime.getTime() + (props.addedTimeMins * 60000)), 1000 );
        }
        return function cleanup() {
            clearInterval(timerID);
        };
   }, [props.addedTimeMins, props.startTime]);
  
   function toHHMMSS(str) {
        var sec_num = parseInt(str, 10);
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    };

    return (
    <div className="time-container">
        <ClockIcon id="clock"/>
        <p className='text-large'>{toHHMMSS(playtime / 1000 )}</p>
    </div>
    );
  }