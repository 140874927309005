import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase"

const functions = getFunctions(app);

const games = httpsCallable(functions, 'listGameMetadata');
const getPlay = httpsCallable(functions, 'getPlay');
const createPlay = httpsCallable(functions, 'createPlay');
const updatePlay = httpsCallable(functions, 'updatePlay');
const round = httpsCallable(functions, 'getRound');

export const ApiService = {
    async listGameMetadata(userId) {
        try {
            const result = await games({ userId: userId });
            if (result && result.data) {
                return result.data;
            }
        } catch (error) {
            console.log("An error occured");
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
        }
    },

    async getRound(userId, gameId, roundNum){
        try {
            const result = await round({ userId: userId, gameId: gameId, roundNum: roundNum });
            if (result && result.data) {
                return result.data;
            }
        } catch (error) {
            console.log("An error occured");
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
        }
    },

    async getPlay(userId, playId){
        try {
            const result = await getPlay({ userId: userId, playId: playId });
            if (result && result.data) {
                return result.data;
            }
        } catch (error) {
            console.log("An error occured");
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
        }
    },

    async createPlay(userId, gameId){
        try {
            const result = await createPlay({ userId: userId, gameId: gameId });
            if (result && result.data) {
                return result.data;
            }
        } catch (error) {
            console.log("An error occured");
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
        }
    },

    async updatePlay(userId, play){
        try {
            const result = await updatePlay({ userId: userId, play: play });
            if (result && result.data) {
                return result.data;
            }
        } catch (error) {
            console.log("An error occured");
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
        }
    },

    async giveUpPlay(userId, play){
        try {
            // TODO: mark status as abandoned, update displayed answers
            
            // 1. Iterate through the "rounds" list, and updated "displayedAnswers" on the Play with
            // the answers for every question in the round (round.questions[questionIdx].answers)
            // 2. Update the Play in the backend
            
            // const result = await round({ userId: userId, gameId: gameId, roundNum: roundNum });

            const result = await updatePlay({ userId: userId, play: play });
            if (result && result.data) {
                return result.data;
            }
        } catch (error) {
            console.log("An error occured");
            console.log(error.code);
            console.log(error.message);
            console.log(error.details);
        }
    },
};

const fakeEntitlementsTable = [
    {userId:"matt", gameId:"game0"},
    {userId:"matt", gameId:"game1"},
    {userId:"matt", gameId:"game2"},
];

const fakePlayTable = [
    {playId:"play0", gameId:"game0", userId:"matt", minutesPlayed:22, lastResumedTime: new Date(2022, 10, 20, 11, 22, 11), correctAnswers: [], completedTime: null, lastUpdated: new Date(2022, 10, 20, 11, 22, 11)},
    {playId:"play1", gameId:"game0", userId:"matt", minutesPlayed:22, lastResumedTime: new Date(2002, 10, 20, 11, 22, 11), correctAnswers: [], completedTime: new Date(2002, 10, 20, 11, 22, 11), lastUpdated: new Date(2002, 10, 20, 11, 22, 11)},
    {playId:"play2", gameId:"game2", userId:"matt", minutesPlayed:44, lastResumedTime: new Date(2022, 10, 20, 11, 22, 11), correctAnswers: [], completedTime: new Date(2022, 10, 20, 11, 22, 11), lastUpdated: new Date(2022, 10, 20, 11, 22, 11)},
];

const fakeGamesTable = [
    {gameId: "game0", name: "Squawk Like an Egyptian", playtimeMins:45},
    {gameId: "game1", name: "Chicken Minis", playtimeMins:15},
    {gameId: "game2", name: "Doneskis", playtimeMins:25},
    {gameId: "game3", name: "Nobody Entitled", playtimeMins:25},
];

const fakeRoundsTable = [
    {roundId:"roundABC", gameId:"game0", roundNum: 0, roundName: "Round 01", songs:["abc", "abc", "abc", "abc", "abc", "abc"]},
    {roundId:"roundDEF", gameId:"game0", roundNum: 1, roundName: "Round 02", songs:["abc", "abc", "abc"]},
    {roundId:"roundGHI", gameId:"game0", roundNum: 2, roundName: "Round 03", songs:["abc", "abc", "abc", "abc"]},
];

const fakeSongsTable = [
    {songId: "abc", data: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3"},
];

const fakeAnswersTable = [
    {answerId: "abc", songId: "abc", answerType: "TITLE", data:"Werewolves in London", answerHash:"def"},
    {answerId: "ced", songId: "abc", answerType: "ARTIST", data:"Jason Isbell", answerHash:"def"},
];

function makeID(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export const FakeApiService = {
    async listGameMetadata_Hardcoded() {
        return [
            {
                gameId: 0,
                name: 'Squawk Like an Egyptian',
                playtimeMins: 45,
                playState: 'PAUSED',
                activePlayId: "play1",
                numArtistsRemaining: 12,
                numSongsRemaining: 5,
            },
            {
                gameId: 1,
                name: 'Chicken minis',
                playtimeMins: 15,
                playState: 'NEVER',
                numArtistsRemaining: 21,
                numSongsRemaining: 21,
            },
            {
                gameId: 2,
                name: 'Doneskis',
                playtimeMins: 25,
                playState: 'COMPLETE',
                numArtistsRemaining: 0,
                numSongsRemaining: 0,
            },
        ];
    },

    async listGameMetadata(userId){
        const entitledGames = fakeEntitlementsTable.filter((entry) => entry.userId === userId).map(entry => entry.gameId);
        return fakeGamesTable.filter((entry) => entitledGames.includes(entry.gameId)).map((entry) => {

            // Get all plays for this game, sorted in order from most recently updated to least recently updated.
            const plays = fakePlayTable.filter(playEntry => playEntry.gameId === entry.gameId).sort(function(x, y){
                return y.lastUpdated - x.lastUpdated;
            });

            let playState = "NEVER";
            let activePlayId = null;
            if(plays.length > 0){
                playState = "COMPLETE";
                if(plays[0].completedTime === null){
                    playState = "PAUSED";
                    activePlayId = plays[0].playId;
                }
            }

            return {
                gameId: entry.gameId,
                name: entry.name,
                playtimeMins: entry.playtimeMins,
                playState: playState,
                activePlayId: activePlayId,

                // TODO: add numArtistsRemaining, numSongsRemaining by reading from round
            };
        });
    },

    async getPlay_Hardcoded(){
        return {
            playId: "play1",
            gameId: "gameABC",
            gameName: "Squawk Like an Egyptian",
            userPlaytimeMins: 152,
            lastResumedTime: new Date(2022, 10, 20, 11, 22, 11),
            rounds: [
                {
                    roundNum: 0,
                    roundName: "Round 01",
                },
                {
                    roundNum: 1,
                    roundName: "Round 02",
                },
                {   
                    roundNum: 2,
                    roundName: "Round 03",
                },
            ],
            displayedAnswers: [
                {
                    roundNum: 0,
                    questionId: 1,
                    artist: 'Bob',
                    title: 'Bobs big song',
                },
            ],
        };
    },

    async getPlay(playId){
        const plays = fakePlayTable.filter((entry) => entry.playId === playId);
        if (plays.length !== 1){
            console.log("Error fetching play");
            return {};
        }
        const play = plays[0];

        // Get rounds for this game, sorted by roundNum
        const rounds = fakeRoundsTable.filter(e => e.gameId === play.gameId).sort(function(x,y){
            return x.roundNum - y.roundNum;
        }).map((round) => {
            return {
                roundNum: round.roundNum,
                roundName: round.roundName,
            };
        });

        return {
            playId: play.playId,
            gameId: play.gameId,
            gameName: fakeGamesTable.filter(e => e.gameId === play.gameId)[0].name,
            userPlaytimeMins: play.minutesPlayed,
            lastResumedTime: play.lastResumedTime,
            rounds: rounds,
            displayedAnswers: [],
        };
    },

    async createPlay(userId, gameId){
        // TODO: check if user is entitled to game

        // Get all plays for this game, sorted in order from most recently updated to least recently updated.
        const plays = fakePlayTable.filter(playEntry => playEntry.gameId === gameId).sort(function(x, y){
            return y.lastUpdated - x.lastUpdated;
        });
        if(plays.length > 0 && plays[0].completedTime === null){
            // We are attempting to create a play when an active play already exists. 
            // Mark the previous play as abandoned by setting the complete time on it.

            // TODO: replace
        }

        // Get rounds for this game, sorted by roundNum
        const rounds = fakeRoundsTable.filter(e => e.gameId === gameId).sort(function(x,y){
            return x.roundNum - y.roundNum;
        }).map((round) => {
            return {
                roundNum: round.roundNum,
                roundName: round.roundName,
            };
        });

        const newPlay = {
            playId: makeID(6),
            gameId: gameId,
            gameName: fakeGamesTable.filter(e => e.gameId === gameId)[0].name,
            userPlaytimeMins: 0,
            startTime: new Date(),
            lastResumedTime: new Date(),
            lastUpdated: new Date(),
            rounds: rounds,
            displayedAnswers: [],
        };

        fakePlayTable.push(newPlay);

        console.log("returning play");
        console.log(newPlay);

        return newPlay;
    },

    async updatePlay(play){

    },

    async getRound(gameId, roundNum){
        const rounds = fakeRoundsTable.filter(e => e.gameId === gameId && e.roundNum === roundNum);
        if (rounds.length !== 1){
            console.log("Error fetching round for gameId: " + gameId + " roundNum: " + roundNum);
            return {};
        }
        const round = rounds[0];
        
        const questions = round.songs.map((songId, idx) => {
            const songs = fakeSongsTable.filter(e => e.songId === songId);
            if (songs.length !== 1){
                console.log("Error fetching song");
                return {};
            }
            const song = songs[0];
            const answers = fakeAnswersTable.filter(e => e.songId === song.songId).map((ans) => {
                return {
                    answerType: ans.answerType,
                    answerHash: ans.answerHash,
                    answerText: ans.data,
                };
            });

            return {
                name: "Question 0" + (idx + 1),
                songId: song.songId,
                url: song.data,
                answers: answers,
            };
        });

        return {
            roundNum: roundNum,
            name: round.roundName,
            questions: questions,
        };
    },

    async getRound_Hardcoded(gameId, roundNum){
        const roundName = 'Round 0' + (roundNum+1)
        return {
            roundNum: roundNum,
            name: roundName,
            questions: [
                {
                    name: "Question 01",
                    songId: 0,
                    url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                    answers: [
                        {
                            answerType: "TITLE",
                            answerHash: "def",
                            answerText: "If it takes a lifetime"
                        },
                        {
                            answerType: "ARTIST",
                            answerHash: "abc",
                            answerText: "Jason Isbell"
                        },
                    ],
                },
                {
                    name: "Question 02",
                    songId: 0,
                    url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                    answers: [],
                },
                {
                    name: "Question 03",
                    songId: 0,
                    url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                    answers: [],
                },
                {
                    name: "Question 04",
                    songId: 0,
                    url: "https://storage.googleapis.com/chickensongs-b5e3d.appspot.com/songs/OEpGBfMZ.mp3",
                    answers: [],
                },
            ],
        }
    },
};