import { ReactComponent as Logo } from '../assets/logo.svg';
import { auth, db } from '../firebase';
import { useState } from "react";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export default function AuthPage() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    async function handleLogin() {
        setFullName("");
        clearError();
        try {
            const res = await signInWithEmailAndPassword(auth, email, password);
            if (res.error) {
                console.log("Error: " + res.error);
                return
            }
            navigate(`/games/`);
        } catch (err) {
            displayError(err.code);
        }
    };

    async function handleRegister() {
        setPassword("");
        clearError();
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);
            if (res.error) {
                console.log("Error: " + res.error);
                return;
            }
            const user = res.user;
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                authProvider: "firebase",
                fullName: fullName,
                email,
            });
            await addDoc(collection(db, "entitlements"), {
                userId: user.uid,
                friendlyName: user.email,
                game: "General Squawks II",
                gameId: "nCIJSqmtMcFUj4EE3Ftn",
            });
            await addDoc(collection(db, "entitlements"), {
                userId: user.uid,
                friendlyName: user.email,
                game: "General Squawks I",
                gameId: "eifRbvpuA9yE5la2o1uc",
            });
            await addDoc(collection(db, "entitlements"), {
                userId: user.uid,
                friendlyName: user.email,
                game: "Chicken Minis I",
                gameId: "sX78wus6pnNo70VtaD54",
            });
            navigate(`/games/`);
        } catch (err) {
            displayError(err.code);
        }
    };

    async function displayRegister() {
        clearError();
        var register = document.getElementById("register");
        var signIn = document.getElementById("sign-in");
        register.style.display = "block";
        signIn.style.display = "none";
    };

    async function displaySignIn() {
        clearError();
        var register = document.getElementById("register");
        var signIn = document.getElementById("sign-in");
        register.style.display = "none";
        signIn.style.display = "block";
    };

    async function displayError(errorCode) {
        console.log(errorCode);
        switch (errorCode) {
            case "ERROR_EMAIL_ALREADY_IN_USE":
            case "auth/account-exists-with-different-credential":
            case "auth/email-already-in-use":
                setErrorMessage("Email already used. Please go to sign-in page.");
                break;
            case "ERROR_WRONG_PASSWORD":
            case "auth/wrong-password":
                setErrorMessage("Wrong password.");
                break;
            case "ERROR_USER_NOT_FOUND":
            case "auth/user-not-found":
                setErrorMessage("No user found with this email.");
                break;
            case "ERROR_USER_DISABLED":
            case "auth/user-disabled":
                setErrorMessage("User disabled.");
                break;
            case "ERROR_TOO_MANY_REQUESTS":
                setErrorMessage("Too many requests to log into this account.");
                break;
            case "ERROR_OPERATION_NOT_ALLOWED":
            case "auth/operation-not-allowed":
                setErrorMessage("Server error, please try again later.");
                break;
            case "ERROR_INVALID_EMAIL":
            case "auth/invalid-email":
                setErrorMessage("Email address is invalid.");
                break;
            case "auth/weak-password":
                setErrorMessage("Please select a longer password.");
                break;
            default:
                setErrorMessage("Login failed. Please try again.");
                break;
        }
        var error = document.getElementById("error");
        error.style.display = "block";
    };

    async function clearError(){
        var error = document.getElementById("error");
        error.style.display = "none";
        setErrorMessage("");
    };

    return (
        <div>
            <section id="login-container" className="hero-card-container">
                <div className="login-card-container">
                    <div className="login-logo text-center">
                        <Logo id="logo" />
                        <h1 className="text-logo">Squawk appella</h1>
                        <p>A game of chickens singing songs</p>
                    </div>
                    <div id="error" style={{ "display": "none" }}>
                        <p>{errorMessage}</p>
                    </div>
                    <div id="sign-in" className="login-form">
                        <div>
                            <input type="text" placeholder="Email" className="text-large text-center" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <input type="password" placeholder="Password" className="text-large text-center" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <div>
                                <input type="submit" value="Sign In" className="text-large" onClick={handleLogin} />
                                <p>Or</p>
                                <input type="submit" value="Register" className="text-large" onClick={displayRegister} />
                            </div>
                        </div>
                    </div>
                    <div id="register" className="login-form" style={{ "display": "none" }}>
                        <div>
                            <input type="text" placeholder="Name" className="text-large text-center" value={fullName} onChange={(e) => setFullName(e.target.value)} required />
                            <input type="text" placeholder="Email" className="text-large text-center" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <input type="password" placeholder="Password" className="text-large text-center" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <div>
                                <input type="submit" value="Register" className="text-large" onClick={handleRegister} />
                                <p>Or</p>
                                <input type="submit" value="Sign In" className="text-large" onClick={displaySignIn} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}