import React from "react";
import { useEffect, useState } from 'react';
import GameListEntry from "../components/gameListEntry";
import Navbar from "../components/navbar";
import GameListSidebar from "../components/gameListSidebar";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../AuthContext";
import { ApiService } from "../services/apiService";
import CircularProgress from '@mui/material/CircularProgress';

export default function GamesListPage() {
    const [games, setGames] = useState([]);
    const [activeGameIdx, setActiveGameIdx] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    
    const { user } = useContext(AuthContext);

    useEffect(() => {
        async function getGamesList() {
            if (!user) {
                return;
            }
            ApiService.listGameMetadata(user.uid).then(result => {
                setGames(result);

                let i = 0;
                for (const game of result) {
                    if (game.playState === 'PAUSED') {
                        // TODO: add lastUpdated to game metadata entry, so that you can make 
                        // the most recently updated paused game the active one
                        setActiveGameIdx(i);
                        break;
                    }
                    i++;
                }
            });
        };
        getGamesList();
    }, [user]);

    function restartGame(gameId) {
        ApiService.createPlay(user.uid, gameId).then(result => {
            // TODO: handle error response
            setIsLoading(true);
            navigate(`/play/${result.playId}?round=1`);
        });
    };

    async function onGameClick(gameId) {
        // TODO:, fix: setActiveGameIdx(gameId);

        const game = games.filter(g => g.gameId === gameId)[0];
        if (game.activePlayId !== null) {
            navigate(`/play/${game.activePlayId}?round=1`);
        } else {
            setIsLoading(true);
            ApiService.createPlay(user.uid, gameId).then(result => {
                // TODO: handle error response
                setIsLoading(true);
                navigate(`/play/${result.playId}?round=1`);
            });
        }
    };

    if (!user) {
        return <div /> //<Navigate to="/" replace />
    } else {
        return (
            <section className="dashboard-container">
                <div className="dashboard-main-container">
                    <Navbar />
                    <div className='dashboard-main'>
                        <div className='main-inner'>
                            <div className='meta-container'>
                                <h2 className='text-title'>My quizzes</h2>
                            </div>
                            <div className='quiz-container'>
                                {!isLoading && (games && games.length > 0) ? (games.map((item, idx) => (
                                    <GameListEntry
                                        key={idx}
                                        name={item.name}
                                        playMins={item.playtimeMins}
                                        onClick={() => onGameClick(item.gameId)}
                                        playState={item.playState} />
                                ), this)) : <CircularProgress />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-sidebar-container">
                    {!isLoading && (games && games.length > 0) ? (<GameListSidebar
                        activeGame={games.some((game) => game.playState === 'PAUSED')}
                        numArtists={games[activeGameIdx].numArtistsRemaining}
                        numSongs={games[activeGameIdx].numSongsRemaining}
                        name={games[activeGameIdx].name}
                        playMins={games[activeGameIdx].playtimeMins}
                        onPlayClick={() => onGameClick(games[activeGameIdx].gameId)}
                        onRestartClick={() => restartGame(games[activeGameIdx].gameId)}
                    />) : <div />}
                </div>
            </section>
        );
    }
}